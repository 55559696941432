import React from "react"


import Layout from '../components/layout'
import { PriceService } from '../components/priceService'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const TorunCennik = () => {
    const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
    `)
  return (
    <Layout torun>
    <Seo
      title="Torun cennik"
      description="Torun cennik"
      url="https://bodymedgroup.pl/torun-cennik"
    />
      <SmallTitlePage
        title='Cennik' 
      />
      <PriceService torun/>
    </Layout>
  )
  }

export default TorunCennik
